import PropTypes from "prop-types";
// @mui
import { Card, Stack, Typography, Divider, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
// utils

const Index = ({ ticketData }) => {
  const { palette } = useTheme();
  return (
    <div>
      <Card
        sx={{
          py: 3,
          color: palette.primary.main,
        }}
      >
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
        >
          <Stack width={1} textAlign="center">
            <Typography variant="subtitle2">
              {moment(ticketData?.created_at).format("DD MMM YYYY")}
            </Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              Created Date
            </Typography>
          </Stack>
          {ticketData?.last_response_date ? (
            <Stack width={1} textAlign="center">
              <Typography variant="subtitle2">
                {moment(ticketData?.last_response_date).format("DD MMM YYYY")}
              </Typography>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                Last Response
              </Typography>
            </Stack>
          ) : null}
        </Stack>
      </Card>
    </div>
  );
};

export default Index;
