import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Scrollbar from "src/components/Scrollbar";
import useFetchDepositWallet from "../hooks/useFetchDepositWallet";
import useLocales from "src/hooks/useLocales";
import { BodyRow } from "src/pages/admin/reports/components";
import EmptyTable from "src/components/emptyTable";
import Map from "src/components/map";
import DataHandlerTable from "src/components/data-handler/table";
import PaginationButtons from "src/components/pagination";

const headers = [
  "userFinancial.depositWallet.no",
  "userFinancial.depositWallet.userName",
  "userFinancial.depositWallet.amountType",
  "userFinancial.depositWallet.paymentType",
  "userFinancial.depositWallet.paymentAmount",
  "userFinancial.depositWallet.date",
];

const DataList = ({ state, rowStart, ...rest }) => {
  const { translate } = useLocales();
  const { data, ...dataProps } = state;

  return (
    <>
      <Card sx={{ mt: 2 }}>
        <Scrollbar>
          <DataHandlerTable
            name="faq-table"
            headers={headers}
            dataProps={{ ...dataProps }}
          >
            <Map
              list={data}
              render={(item, i) => (
                <>
                  <TableRow key={item.id}>
                    <TableCell>{i + rowStart}</TableCell>
                    <TableCell>{item.from_user.username}</TableCell>
                    <TableCell>{item.payment_type}</TableCell>
                    <TableCell sx={{ textTransform: "capitalize" }}>
                      {item.type}
                    </TableCell>
                    <TableCell>{item.total_amount}</TableCell>
                    <TableCell>
                      {new Date(item.created_at).toLocaleDateString("en-GB")}
                    </TableCell>
                  </TableRow>
                </>
              )}
            />
          </DataHandlerTable>
        </Scrollbar>
      </Card>
      <PaginationButtons {...rest} />
    </>
  );
};

export default DataList;
